<template>
  <div id="jeopardy">
    <TitleHelper :title="recordedName ? 'Let\'s Play Jeopardy, ' + recordedName + '!' : 'Let\'s Play Jeopardy!'"></TitleHelper>
    <div>
        <span style="font-family: 'jeopardy_font'; color: orange; text-shadow: 1px 1px black; font-size: 30px;">
            <div>Show: <span style="color: #977700;">{{show_number}}</span>, Broadcast Number: <span style="color: #977700;">{{broadcast_number}}</span> <span style="font-size: 20px; color: #977700;">({{broadcast_date}})</span></div>
            <div>Currently Playing: 
                <span style="color: #977700;">
                    <span v-if="!jeopardyGameDone">Jeopardy</span>
                    <span v-if="jeopardyGameDone && !doubleJeopardyGameDone">Double Jeopardy</span>
                    <span v-if="jeopardyGameDone && doubleJeopardyGameDone">Final Jeopardy</span>
                </span>
            </div>
            <div style="display: flex; white-space: pre-wrap;">Game Code:&nbsp;
                <div style="color: #977700;">{{gameCode}}</div>
                <div class="socketConnected" v-if="isConnected && gameCode"></div>
            </div>
            <div>
                <span v-if="recordedName">Name: {{recordedName}}
                    <button @click="changeName" style="position:relative; bottom: 5px;">Change Name</button>
                </span>
                <span v-if="!recordedName">
                    <input v-model="playerName"><button @click="storeName">Store Name</button>
                </span>
            </div>
        </span>

        <div class="buzzer" v-if="recordedName && !buzzDisable" @click="buzz"></div>
        <div class="disabled buzzer" v-if="recordedName && buzzDisable"></div>
        

        <div class="questionBox">
            <div v-if="jeopardyGameDone && doubleJeopardyGameDone && finalJeopardyCategory" style="margin-top: 8px;" class="finalJeopardy">
                <div>FINAL JEOPARDY</div>
                <div><span style="font-family:'Avenir'">The category is: </span>{{finalJeopardyCategory}}</div>
                <div v-if="finalJeopardyQuestion"><span style="font-family:'Avenir'">The clue is: </span>{{finalJeopardyQuestion}}</div>
                <div v-if="(!finalJeopardyAcceptedWager && finalJeopardyAcceptedWager != 0) && !finalJeopardyWagerSubmitted" class="wager-input">
                    <input type="number" v-model="finalJeopardyWager">
                    <i>$</i>
                    <button @click="submitWager" style="position: relative; left: 5px;">Submit Wager</button>
                </div>
                <div v-if="finalJeopardyAcceptedWager || finalJeopardyAcceptedWager == 0" class="question-input">
                    <span style="font-family:'Avenir'">Accepted wager: ${{this.finalJeopardyAcceptedWager}}</span>
                    <div v-if="finalJeopardyQuestion && !finalJeopardyGuessSubmitted">
                        <input v-model="finalJeopardyGuess" style="width: 30%;">
                        <button @click="submitFinalJeopardyGuess" style="position: relative; left: 5px;">Submit Question</button>
                    </div>
                </div>
            </div>
            <span v-if="selected && (!jeopardyGameDone || !doubleJeopardyGameDone)">
                <div class="question">{{question}}</div>
            </span>
        </div>  

        <div v-if="players.length > 0">
            <span v-for="(player,position) in players" :key="'player'+position" class="playerBox">
                <div v-if="player.control" class="control-indicator"></div>
                <div class="player">
                    <div class="player-name">{{player.name}}</div>
                    <div class="score">${{player.score}}</div>
                </div>
            </span>
        </div>

        <br /><br />
        <table v-if="!jeopardyGameDone">
            <tr>
                <td v-for="(item, index) in jeopardyBoard.categories" :key="'jc'+index">
                    <span class="categories">{{item}}</span>
                </td>
            </tr>
            <tr v-for="(row,index) in jeopardyBoard.questions" :key="'jr'+index">
                <td v-for="item in row" :key="item.label" :class="{'selected':item.label == selected}">
                    <span v-if="item.answered==false" class="money">
                        ${{item.value}}
                    </span>
                </td>
            </tr>
        </table>
        <table v-if="jeopardyGameDone && !doubleJeopardyGameDone">
            <tr>
                <td v-for="(item, index) in doubleJeopardyBoard.categories" :key="'djc'+index">
                    <span class="categories">{{item}}</span>
                </td>
            </tr>
            <tr v-for="(row,index) in doubleJeopardyBoard.questions" :key="'djr'+index">
                <td v-for="item in row" :key="item.label" :class="{'selected':item.label == selected}">
                    <span v-if="item.answered==false" class="money">${{item.value}}</span>
                </td>
            </tr>
        </table>
    </div>
  </div>
</template>


<script>
import TitleHelper from '../../helpers/vueTitleHelper.vue';

export default {
  name: "jeopardyPlayer",
  beforeCreate: function() {
    document.body.className = 'jeopardy';
  },
  data() {
    return {
        isConnected: false,
        show: null,
        errorValue: null,
        categories: null,
        questions: null,
        show_number: null,
        broadcast_number: null,
        broadcast_date: null,
        jeopardyBoard: {},
        doubleJeopardyBoard: {},
        finalJeopardyCategory: null,
        finalJeopardyQuestion: null,
        finalJeopardyWager: null,
        finalJeopardyWagerSubmitted: false,
        finalJeopardyAcceptedWager: null,
        finalJeopardyGuess: null,
        finalJeopardyGuessSubmitted: false,
        jeopardyBoardValueLookup: {
            1: 200,
            2: 400,
            3: 600,
            4: 800,
            5: 1000,
        },
        doubleJeopardyBoardValueLookup: {
            1: 400,
            2: 800,
            3: 1200,
            4: 1600,
            5: 2000,
        },
        selected: null,
        selectedBoard: null,
        players: [],
        gameCode: null,
        question: null,
        playerName: null,
        recordedName: null,
        buzzDisable: false,
        feedback: null,
    };
  },
  mounted() {
      if (this.$route.params.pathMatch) {
          this.gameCode = this.$route.params.pathMatch;
      } else {
          this.error = "No game specified";
      }
      this.$socket.emit('joinJeopardyRoom', this.gameCode);
      if (this.$cookies.isKey('jeopardyName')) {
          this.recordedName = this.$cookies.get('jeopardyName');
          this.$socket.emit('reconnectToJeopardyRoom', this.gameCode, this.recordedName);
      }
      if (this.$cookies.isKey('buzz')) {
          this.buzzDisable = JSON.parse(this.$cookies.get('buzz'));
      }
      if (this.$cookies.isKey('finalJeopardyCategory')) {
          this.finalJeopardyCategory = this.$cookies.get('finalJeopardyCategory');
      }
      if (this.$cookies.isKey('finalJeopardyQuestion')) {
          this.finalJeopardyQuestion = this.$cookies.get('finalJeopardyQuestion');
      }
      if (this.$cookies.isKey('finalJeopardyAcceptedWager')) {
          this.finalJeopardyAcceptedWager = parseInt(this.$cookies.get('finalJeopardyAcceptedWager'));
      }
      if (this.$cookies.isKey('finalJeopardyGuessSubmitted')) {
          this.finalJeopardyGuessSubmitted = this.$cookies.get('finalJeopardyGuessSubmitted');
      }
      window.addEventListener("keypress", function(e) {
        if (!this.buzzDisable){
            if (e.keyCode == 32) {
		this.$socket.emit('buzz', this.gameCode);
            }
        }
      }.bind(this));
  },
  components: {
    TitleHelper,
  },
  sockets: {
    connect() {
        this.isConnected = true;
    },
    disconnect() {
        this.isConnected = false;
    },
    socketId(id) {
        if(this.$cookies.isKey('socketId')) {
            let oldId = this.$cookies.get('socketId')
            if (oldId != id) {
                this.$socket.emit('joinJeopardyRoom', this.gameCode);
                this.$cookies.set('socketId', id);
            }
        } else {
            this.$cookies.set('socketId', id);
            this.$socket.emit('joinJeopardyRoom', this.gameCode);
        }
    },
    boardReceive(boards) {
        this.jeopardyBoard = boards[0];
        this.doubleJeopardyBoard = boards[1];
        this.show_number = boards[2];
        this.broadcast_number = boards[3];
        this.broadcast_date = boards[4];
    },
    selectedReceive(selected) {
        this.selected = selected[0];
        this.selectedBoard = selected[1];
    },
    questionReceive(question) {
        this.question = question;
    },
    playersReceive(players) {
        this.players = players;
    },
    buzzKill() {
        this.buzzDisable = true;
        this.$cookies.set('buzz',true);
    },
    buzzRestore() {
        this.buzzDisable = false;
        this.$cookies.set('buzz',false);
    },
    newPlayerName(name) {
        this.recordedName = name;
        this.$cookies.set('jeopardyName', name);
    },
    timeUpSound() {
        this.playTimeUpSound();
    },
    finalJeopardyCategoryReceived(category) {
        this.finalJeopardyCategory = category;
        this.finalJeopardyQuestion = null;
        this.finalJeopardyWager = null;
        this.finalJeopardyWagerSubmitted = false;
        this.finalJeopardyAcceptedWager = null;
        this.finalJeopardyGuess = null;
        this.finalJeopardyGuessSubmitted = false;
        this.$cookies.set('finalJeopardyCategory', category);
        this.$cookies.remove('finalJeopardyQuestion');
        this.$cookies.remove('finalJeopardyAcceptedWager');
        this.$cookies.remove('finalJeopardyGuessSubmitted');
    },
    finalJeopardyWagerAcceptanceReceived(value) {
        this.finalJeopardyAcceptedWager = value;
        this.$cookies.set('finalJeopardyAcceptedWager', value);
    },
    finalJeopardyWagerRejectReceived() {
        this.finalJeopardyWagerSubmitted = false;
    },
    finalJeopardyQuestion(question) {
        this.finalJeopardyQuestion = question;
        this.$cookies.set('finalJeopardyQuestion', question);
    },
    resetPlayerStatus() {
        this.finalJeopardyCategory = null;
        this.finalJeopardyQuestion = null;
        this.finalJeopardyWager = null;
	this.finalJeopardyWagerSubmitted = false;
        this.finalJeopardyAcceptedWager = null;
        this.finalJeopardyGuess = null;
        this.finalJeopardyGuessSubmitted = false;
        this.$cookies.remove('finalJeopardyCategory');
        this.$cookies.remove('finalJeopardyQuestion');
        this.$cookies.remove('finalJeopardyAcceptedWager');
        this.$cookies.remove('finalJeopardyGuessSubmitted');
    }
  },
  methods: {
    playTimeUpSound() {
        // var audio = new Audio('../../../timeup.mp3');
        // audio.play();
    },
    storeName() {
        if (this.playerName){
            if (this.$cookies.isKey('jeopardyName')) {
                var oldName = this.$cookies.get('jeopardyName');
                this.$socket.emit('amendJeopardyName', this.gameCode, oldName, this.playerName);
            } else {
                this.$socket.emit('addJeopardyName', this.gameCode, this.playerName);
            }
        }
    },
    changeName() {
        this.recordedName = null;
    },
    buzz() {
        this.$socket.emit('buzz', this.gameCode);
    },
    playerNameAlreadyExists() {
        this.playerName = null;
    },
    submitWager() {
        if (!isNaN(parseInt(this.finalJeopardyWager)) && this.finalJeopardyWager > -1) {
            this.$socket.emit('finalJeopardyWagerSubmit', this.gameCode, parseInt(this.finalJeopardyWager))
            this.finalJeopardyWager = null;
            this.finalJeopardyWagerSubmitted = true;
        }
    },
    submitFinalJeopardyGuess() {
        this.$socket.emit('finalJeopardyGuessSubmit', this.gameCode, this.finalJeopardyGuess);
        this.finalJeopardyGuessSubmitted = true;
        this.$cookies.set('finalJeopardyGuessSubmitted', true);
    },
  },
  computed: {
    jeopardyGameDone: function() {
        if (this.jeopardyBoard && this.jeopardyBoard.hasOwnProperty('questions') && this.jeopardyBoard.questions[0].length > 0) {
            for (var row of this.jeopardyBoard.questions){
                for (var item of row) {
                    if (item.answered == false) {
                        return false;
                    }
                }
            }
        }
        return true;
    },
    doubleJeopardyGameDone: function() {
        if (this.doubleJeopardyBoard && this.doubleJeopardyBoard.hasOwnProperty('questions') && this.doubleJeopardyBoard.questions[0].length > 0) {
            for (var row of this.doubleJeopardyBoard.questions){
                for (var item of row) {
                    if (item.answered == false) {
                        return false;
                    }
                }
            }
        }
        return true;
    }
  }
};
</script>

<style scoped>

@font-face {
    font-family: 'jeopardy_font';
    src: url('./fonts/gyparody_hv-webfont.woff2') format('woff2'),
         url('./fonts/gyparody_hv-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'dollar_font';
    src: url('./fonts/swiss911_xcm_bt-webfont.woff2') format('woff2'),
         url('./fonts/swiss911_xcm_bt-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'clue_font';
    src: url('./fonts/korinnaboldbt-webfont.woff2') format('woff2'),
         url('./fonts/korinnaboldbt-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

#jeopardy {
    background-image: linear-gradient(to bottom,purple 0%,darkblue 80%, purple 100%);
    min-height: calc(100vh);
}

table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    user-select: none;
}


td {
    height: 50px;
    text-align: center;
    vertical-align: middle;
    background-color: blue;
    border: 2px solid #3a251f;
    color: white;
    overflow-x: hidden;
    user-select: none;
    cursor: default;
}

td.selected {
    padding: 5px yellow;
    background-color: red;
}

td span.money {
    font-size: xx-large;
    font-family: 'dollar_font';
    color: yellow;
    text-shadow: 2px 2px black;
    user-select: none;
}

td span.categories {
    font-family: 'clue_font';
    text-shadow: 1px 1px black;
    user-select: none;
}

div.questionBox {
    margin: 10px 5px;
    min-height: 60px;
    background-color: blue;
    color: white;
    font-family: 'clue_font';
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    padding: 5px;
}

div.questionBox div.question {
    color: white;
    padding: 5px;
    text-shadow: 1px 1px black;
}

div.questionBox div.answer {
    color: yellow;
    padding: 5px;
    text-shadow: 1px 1px black;
}

span.playerBox {
    margin: 10px 5px;
    display: inline-block;
    width: 100px;
    text-align: center;
    position: relative;
}

.playerBox button {
    width: 90%;
}

div.player {
    background-color: #507940;
    border-radius: 5px;
    font-family: "clue_font";
    color: white;
    text-align: center;
    padding: 5px 0px;
}

div.player .score {
    background-color: #2a3f22;
    font-family: "dollar_font";
    color: yellow;
    font-size: x-large;
    padding: 5px;
    width: 80%;
    margin: 5px auto;
    border-radius: 10px;
}

div.player button {
    margin: 3px;
    border: 0;
    border-radius: 3px;
}

div.player div.player-name {
    width: 88px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 6px;
    margin-right: 6px;
}

div.player button.right {
    background-color: rgb(0, 180, 0);
    color: white;
}

div.player button.wrong {
    background-color: rgb(160, 0, 0);
    color: black;
}

div.player button.remove {
    background-color: grey;
    color: black;
}

div.socketConnected {
    background-color: green;
    border-radius: 100px;
    width: 15px;
    height: 15px;
    margin: 10px;
}

div.buzzer {
    background-color: red;
    height: 80px;
    width: 80px;
    border-radius: 80px;
    position: absolute;
    top: 20px;
    right: 20px;
    box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
}

div.buzzer:hover {
    background: radial-gradient(red, yellow)
}

div.buzzer.disabled {
    background-color: grey;
    box-shadow: none;
    opacity: 0.3;
}

div.buzzer.disabled:hover {
    background: grey;
}

button {
    border: none;
    border-radius: 4px;
    font-family: 'Avenir';
    user-select: none;
}

button:hover {
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    background-color: #ccc;
}

div.finalJeopardy {
    font-family: 'clue_font';
    text-shadow: 1px 1px black;
    color: white;
    padding: 5px;
    text-transform: uppercase;
}

div.finalJeopardy div {
    margin: 5px;
}

div.wager-input {
    position: relative;
    display: -webkit-inline-flex;
}

div.wager-input > input {
    text-align: right;
}

div.wager-input > i {
    position: absolute;
    display: block;
    transform: translate(0, -50%);
    top: 50%;
    pointer-events: none;
    width: 25px;
    text-align: center;
    font-style: normal;
    text-shadow: none;
    color: black;
}

div.control-indicator {
    position: absolute;
    background-color: greenyellow;
    width: 10px;
    height: 10px;
    z-index: 1;
    border-radius: 10px;
    top: 55px;
    left: 2px;
    border: 3px double black;
}

</style>
